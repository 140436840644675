.viewDetails td {
  font-weight: bold;
  color: #2f4483;
}

tbody.saleContain {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}
thead.saleContain,
tbody.saleContain tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tbody.searchContain {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
thead.searchContain,
tbody.searchContain tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0; /* Remove any margin for consistency */
}

/* Optional: Add padding and border for consistent UI */
input[type='number'] {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.sale-records td {
  background-color: #edf0f4;
}
